
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.main_youtube_html_render_for_tasks {
  .row {
    flex-direction: row-reverse;
    ul {
      text-align: right;
      list-style: none;
      margin-left: 0 !important;
      padding-left: 0 !important;
      li {
        text-align: right;
        position: relative;
        padding-right: 17px;
        text-align: right;
        &::before {
          position: absolute;
          content: "";
          background-color: #706f6f;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          right: 0;
          top: 7px;
        }
      }
    }
  }
}

.new_wrapper_of_use_patterns {
  .want_break_word {
    p {
      span {
        word-wrap: break-word;
        white-space: pre-wrap !important;
      }
    }
  }
}
// Start the_main_container_for_print_all_pdf
.the_main_container_for_print_all_pdf {
  .el-collapse-item__header {
    padding: 2.5rem 0;
  }
  .for-task-description {
    .sequence {
      display: inline-block;
      color: #39c;
      margin: 0;
      // padding: 3px;
      padding: 0 0.3rem;
      font-weight: bold;
      position: relative;
      transition: 0.5s;
      background: transparent;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        height: 1.5px;
        top: 100%;
        background-color: #39c;
      }
      &::after {
        position: absolute;
        // content: attr(data-tooltip);
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: auto;
        bottom: 85%;
        background-color: #000;
        color: #fff;
        min-width: 70px;
        text-align: center;
        display: none;
        transition: 0.5s;
        font-size: 16px;
        border-radius: 5px;
      }
      .span_for_tooltips {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: auto;
        bottom: 100%;
        background-color: #333;
        color: #fff;
        min-width: 70px;
        text-align: center;
        display: none;
        transition: 0.5s;
        font-size: 16px;
        border-radius: 5px;
        z-index: 555;
        &:hover {
          display: none;
          pointer-events: none;
        }
        &::before {
          position: absolute;
          content: "";
          left: 50%;
          transform: translateX(-50%);
          top: 100%;
          border-style: solid;
          border-width: 10px;
          border-color: #333 transparent transparent transparent;
        }
      }
      &:hover {
        .span_for_tooltips {
          display: block;
        }
      }
    }
    .precision {
      /* margin: 5px; */
      display: inline-block;
      color: #3c3;
      // text-decoration: underline;
      margin: 0;
      padding: 0 0.3rem;
      font-weight: bold;
      position: relative;
      background: transparent;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        height: 1.5px;
        top: 100%;
        background-color: #3c3;
      }
      .span_for_tooltips {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: auto;
        bottom: 100%;
        background-color: #333;
        color: #fff;
        min-width: 70px;
        text-align: center;
        display: none;
        transition: 0.5s;
        font-size: 16px;
        border-radius: 5px;
        z-index: 555;
        &:hover {
          display: none;
          pointer-events: none;
        }
        &::before {
          position: absolute;
          content: "";
          left: 50%;
          transform: translateX(-50%);
          top: 100%;
          border-style: solid;
          border-width: 10px;
          border-color: #333 transparent transparent transparent;
        }
      }
      &:hover {
        .span_for_tooltips {
          display: block;
        }
      }
    }
    .technical_reasoning {
      display: inline-block;
      color: #f93;
      margin: 0;
      // padding: 3px;
      padding: 0 0.3rem;
      font-weight: bold;
      position: relative;
      background: transparent;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        height: 1.5px;
        top: 100%;
        background-color: #f93;
      }
      .span_for_tooltips {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: auto;
        bottom: 100%;
        background-color: #333;
        color: #fff;
        min-width: 70px;
        text-align: center;
        display: none;
        transition: 0.5s;
        font-size: 16px;
        border-radius: 5px;
        z-index: 555;
        &:hover {
          display: none;
          pointer-events: none;
        }
        &::before {
          position: absolute;
          content: "";
          left: 50%;
          transform: translateX(-50%);
          top: 100%;
          border-style: solid;
          border-width: 10px;
          border-color: #333 transparent transparent transparent;
        }
      }
      &:hover {
        .span_for_tooltips {
          display: block;
        }
      }
    }

    .confluence {
      display: inline-block;
      color: #c36;
      margin: 0;
      // padding: 3px;
      padding: 0 0.3rem;
      font-weight: bold;
      position: relative;
      background: transparent;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        height: 1.5px;
        top: 100%;
        background-color: #c36;
      }
      .span_for_tooltips {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: auto;
        bottom: 100%;
        background-color: #333;
        color: #fff;
        min-width: 70px;
        text-align: center;
        display: none;
        transition: 0.5s;
        font-size: 16px;
        border-radius: 5px;
        z-index: 555;
        &:hover {
          display: none;
          pointer-events: none;
        }
        &::before {
          position: absolute;
          content: "";
          left: 50%;
          transform: translateX(-50%);
          top: 100%;
          border-style: solid;
          border-width: 10px;
          border-color: #333 transparent transparent transparent;
        }
      }
      &:hover {
        .span_for_tooltips {
          display: block;
        }
      }
    }
  }
}
